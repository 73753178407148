<template>
  <b-table
    v-if="columns.length"
    @sort-changed="sortChanged"
    :fields="columns"
    :items="items"
    :busy="busy"
    :stickyHeader="true"
    :responsive="true"
    :bordered="bordered"
    :borderless="borderless"
    :striped="striped"
    :outlined="outlined"
    :small="small"
    :hover="hover"
    :dark="dark"
    :fixed="fixed"
    :footClone="footClone"
    :noBorderCollapse="noCollapse"
    :empty-text="$t('general.noRecord')"
    :empty-filtered-text="$t('general.noRecord')"
    :no-local-sorting="true"
    show-empty
    class="list-table"
    :class="{
      archived: archived,
    }"
  >
    <template #cell(increasement)="data">
      <div v-if="isSelect">
        <b-form-checkbox
          class="p-0"
          :value="true"
          :unchecked-value="false"
          @change="onCheckChange($event, data.item)"
          :checked="isChecked(data.item.id)"
        ></b-form-checkbox>
      </div>
      <div v-else>
        <b-link
          class="link-underline"
          @click="view(data.item)"
          v-if="viewModal"
        >
          {{ calculateOrderNumber(data.index) }}
        </b-link>
        <span v-else>
          {{ calculateOrderNumber(data.index) }}
        </span>
      </div>
    </template>

    <template #cell(createdAt)="data">
      {{ data.item.createdAt | formatDate }}
    </template>
    <template #cell(updatedAt)="data">
      {{ data.item.updatedAt | formatDate }}
    </template>
    <template #cell(deletedAt)="data">
      {{ data.item.deletedAt | formatDate }}
    </template>
    <template #cell(createdBy)="data">
      <b-link
        class="link-underline"
        :to="moveTo(data.item.createdBy.id, data.item.createdBy.typeId)"
        v-if="data.item.createdBy"
      >
        {{ data.item.createdBy.name }}
      </b-link>
      <span v-else>-</span>
    </template>
    <template #cell(updatedBy)="data">
      <b-link
        class="link-underline"
        :to="moveTo(data.item.updatedBy.id, data.item.updatedBy.typeId)"
        v-if="data.item.updatedBy"
      >
        {{ data.item.updatedBy.name }}
      </b-link>
      <span v-else>-</span>
    </template>
    <template #cell(deletedBy)="data">
      <b-link
        class="link-underline"
        :to="moveTo(data.item.deletedBy.id, data.item.deletedBy.typeId)"
        v-if="data.item.deletedBy"
      >
        {{ data.item.deletedBy.name }}
      </b-link>
    </template>
    <template #cell(status)="data">
      <b-badge pill variant="success" badge-glow v-if="data.item.isEnable">
        {{ $t(`field.active`) }}
      </b-badge>
      <b-badge pill variant="danger" badge-glow v-else>
        {{ $t(`field.inactive`) }}
      </b-badge>
    </template>
    <template #cell(actions)="data">
      <div class="text-nowrap">
        <slot name="front-button" :item="data.item"></slot>
        <b-button
          :to="{
            name: `view-${route}`,
            params: { id: data.item.id },
          }"
          v-b-tooltip.hover
          :title="$t('button.view')"
          variant="primary"
          pill
          size="sm"
          v-if="viewable"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
        <b-button
          v-if="$can('create', resource) && clonable && !archived"
          :to="{
            name: `create-${route}`,
            query: { id: data.item.id },
          }"
          v-b-tooltip.hover
          :title="$t('button.clone')"
          variant="primary"
          pill
          size="sm"
        >
          <feather-icon icon="CopyIcon" />
        </b-button>
        <b-button
          v-if="$can('update', resource) && updatable && !archived"
          :to="{
            name: `update-${route}`,
            params: { id: data.item.id },
          }"
          v-b-tooltip.hover
          :title="$t('button.update')"
          variant="primary"
          pill
          size="sm"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          v-if="$can('delete', resource) && deletable && !archived"
          v-b-tooltip.hover
          :title="$t('button.delete')"
          variant="danger"
          pill
          size="sm"
          @click="destroy(data.item.id, data.index, data.item)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
        <b-button
          v-if="$can('restore', resource) && deletable && archived"
          v-b-tooltip.hover
          :title="$t('button.restore')"
          variant="success"
          pill
          size="sm"
          @click="restore(data.item.id, data.index, data.item)"
        >
          <feather-icon icon="RepeatIcon" />
        </b-button>
      </div>
    </template>

    <template #table-busy>
      <div class="text-center my-1">
        <b-skeleton-table
          v-if="visibleColumns.length"
          :rows="10"
          :columns="visibleColumns.length"
          :hide-header="true"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
        <b-skeleton-table
          v-else
          :rows="10"
          :columns="fields.length"
          :hide-header="true"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </div>
    </template>

    <template #head()="scope">
      <div class="text-nowrap">{{ $t(scope.label) }}</div>
    </template>

    <template #cell()="scope">
      <div class="text-nowrap" v-if="scope.field.type == 'image'">
        <img
          class="cursor-pointer"
          v-if="scope.item[scope.field.key]"
          :width="scope.field.width ? scope.field : `50px`"
          :src="scope.item[scope.field.key]"
          :alt="scope.field.key"
          @click="previewImage(scope.item[scope.field.key])"
        />
      </div>
      <div class="text-nowrap" v-else>
        <span v-if="scope.item[scope.field.key]">
          {{ scope.item[scope.field.key] }}
        </span>
        <span v-else>
          {{
            scope.field.defaultValue != null ? scope.field.defaultValue : "-"
          }}
        </span>
      </div>
    </template>

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </b-table>
</template>

<script>
import {
  BTable,
  BSkeletonTable,
  BSpinner,
  BLink,
  BButton,
  BFormCheckbox,
  BBadge,
} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BTable,
    BSkeletonTable,
    BSpinner,
    BLink,
    BButton,
    BFormCheckbox,
    BBadge,
  },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
    visibleColumns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: function () {
        return this.$store.state.pagination.perPage;
      },
    },
    viewable: {
      type: Boolean,
      default: false,
    },
    clonable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    updatable: {
      type: Boolean,
      default: true,
    },
    viewModal: {
      type: Boolean,
      default: false,
    },
    archived: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: "",
    },
    route: {
      type: String,
      default: "",
    },
    primaryField: {
      type: String,
      default: "name",
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      item: {},
      columns: [],
    };
  },
  mounted() {
    this.checkVisibleColumn();
  },
  methods: {
    isChecked(id) {
      return this.selected.includes(id);
    },
    checkVisibleColumn() {
      if (this.visibleColumns.length) {
        let columns = [];
        this.fields.forEach((element) => {
          if (this.visibleColumns.includes(element.key) || element.allowAllow) {
            columns.push(element);
          }
        });
        this.columns = [...columns];
      } else {
        this.columns = [...this.fields];
      }
    },
    moveTo(userId, userType) {
      if (userType == 1) {
        return {
          name: "view-user",
          params: { id: userId },
        };
      }
      return {
        name: "view-logistic-inspector",
        params: { id: userId },
      };
    },
    view(item) {
      this.$emit("view", item);
    },
    previewImage(image) {
      const preview = this.$imagePreview({
        initIndex: 0,
        images: [image],
        zIndex: 9999,
        isEnableDownloadImage: false,
        isEnableImagePageIndicator: false,
        isEnableBlurBackground: true,
        onClose: () => {},
      });
    },
    calculateOrderNumber(index) {
      const from = (this.currentPage - 1) * this.perPage + 1;
      return from + index;
    },
    submit() {
      this.$emit("submit");
    },
    sortChanged(ctx) {
      const item = this.fields.find((value) => {
        if (value.key === ctx.sortBy) {
          return value;
        }
      });

      if (!item) {
        return;
      }

      const sortBy = item?.sortField ? item.sortField : item?.key;
      const sortDirection = ctx.sortDesc ? "desc" : "asc";
      this.$emit("sort-changed", sortBy, sortDirection);
    },
    destroy(id, index, data) {
      index = this.calculateOrderNumber(index);

      this.$bvModal
        .msgBoxConfirm(
          this.$t("alert.unableToRevertAfterDelete") +
            ` #${index}: ${data[this.primaryField]}`,
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("button.delete"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$emit("destroy", id);
          }
        });
    },
    restore(id, index, data) {
      index = this.calculateOrderNumber(index);

      this.$bvModal
        .msgBoxConfirm(
          this.$t("alert.unableToRevertAfterRestore") +
            ` #${index}: ${data[this.primaryField]}`,
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: this.$t("button.restore"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$emit("restore", id);
          }
        });
    },
    onCheckChange(check, item) {
      this.$emit("select", item, check);
    },
  },
  setup() {
    // App Name
    const {
      bordered,
      borderless,
      striped,
      outlined,
      small,
      hover,
      dark,
      fixed,
      footClone,
      noCollapse,
    } = $themeConfig.table;
    return {
      bordered,
      borderless,
      striped,
      outlined,
      small,
      hover,
      dark,
      fixed,
      footClone,
      noCollapse,
    };
  },
};
</script>

<style scoped>
</style>
